import React from 'react'
import { graphql } from 'gatsby'
import dayjs from 'dayjs'
import Layout from '../layouts/de'
import PublicationCard from '../components/PublicationCard'
import SubTitle from '../components/Titles/SubTitle'

const lang = 'de'
export default class PublicationPage extends React.Component {
  render() {
    const { data, location } = this.props
    let publications = data.publications.edges
    publications = publications.filter(
      (pub) => pub.thesis !== 'master' && pub.thesis !== 'bachelor'
    )

    // sort publications by year
    const items = []
    const publicationsByYear = new Map()
    publications.forEach((p) => {
      const year = dayjs(p.node.publication_date).year()
      if (publicationsByYear.has(year)) publicationsByYear.get(year).push(p)
      else publicationsByYear.set(year, [p])
    })

    // create publication elements per year
    const sorted = new Map(
      Array.from(publicationsByYear).sort((a, b) => b[0] - a[0])
    )
    sorted.forEach((pubItems, k) =>
      k
        ? items.push(
            <article key={String(k)}>
              {pubItems.map((pub) => (
                <PublicationCard content={pub.node} year={k} lang={lang} />
              ))}
            </article>
          )
        : null
    )

    return (
      <Layout location={location}>
        <SubTitle>Publikationen.</SubTitle>
        {items}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    publications: allDirectusPublication {
      edges {
        node {
          authors
          title
          slug
          publisher
          publication_date
          link
          type
          bibtex_entry
          thesis
          honorable_mention
          best_paper
          best_student_paper
          reviewers_choice
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
  }
`
